import React, { FC } from "react";
import { contactPoland } from "@/utils/companyContactData";

export const FooterCompanyLocations: FC = () => {
  const logoDertourLocation = "/images/dertour/logo_dertour_footer_red.png";

  return (
    <div className="d-flex-box">
      <div>
        <img src={logoDertourLocation} alt="Dertour" />
      </div>
      <div className="l-6-cols-desktop">
        <address className="footer-location l-4-cols-tablet l-bleed-before">
          <strong className="country">Headquarters: Poland</strong>
          <br />
          {contactPoland.streetAndNumber}
          <br />
          {contactPoland.postcodeAndCity}
        </address>
      </div>
    </div>
  );
};
