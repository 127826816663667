import React, { FC } from "react";

export const FooterIntro: FC = () => (
  <div className="l-4-cols-desktop">
    <img
      className="footer-logo"
      alt="anixe logo"
      src="/images/logos/anixelogo.svg"
    />
    <address className="footer-contact clearfix">
      <p className="l-with-half-gutter-bottom-mobile">
        How can we help? Contact us any time.
      </p>
      <a href="tel:+48713647950" className="color-primary">
        <strong>+48 71 364 79 50</strong>
      </a>
    </address>
    {/*<a*/}
    {/*  className="link-button button button-ghost-quaternary v-align-top l-push-top-mobile"*/}
    {/*  href="/estimate-project"*/}
    {/*>*/}
    {/*  estimate project*/}
    {/*</a>*/}
    <a
      className="link-button button button-ghost-primary v-align-top l-push-top-mobile"
      href="/demo-form"
    >
      request a demo
    </a>
  </div>
);
